.fon{
    background: rgba(0, 0, 0, 0.50);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}
._{
    display: flex;
    position: fixed;
    width: calc(100% - 32px);
    height: calc(100% - 60px);
    top: 0;
    left: 0;
    gap: 30px;
    background: #15191F;
    z-index: 10;
    padding: 30px 16px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
}
.block{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}
.logo{
    width: 100%;
    border-radius: 10px;
}
.text{
    color: #FFFFFF;
}