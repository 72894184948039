.fon{
    background: rgba(0, 0, 0, 0.50);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
}
.menu{
    border-radius: 20px 20px 0 0;
    background: #15191F;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.closeBlock{
    width: calc(100% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: row-reverse;
}
.close{
    cursor: pointer;
}
.menuText1{
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
}
.menuText2{
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 30px;
}
.menuText3{
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.table{
    margin: 10px 0 20px 0;
}
.table2{
    margin: 10px 0 60px 0;
}
.menuText4{
    color: #000000;
    background: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 5px;
    padding: 2px 5px;
}
.menuText5{
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.menuText6{
    color: #01b454;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.menuButtons{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 24px;
    align-items: center;
}
.block{
    width: calc(100% - 32px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.input{
    background: #15191F;
    border-radius: 10px;
    border: 1px solid #34373D;
    height: 54px;
    padding-left: 16px;
    width: 100%;
    margin: 0 10px;
    color: #FFF;
    font-feature-settings: 'case' on;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
}
.blockText{
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
}
.blockText2{
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: calc(100% - 32px);
    margin: 0 0 10px 0;
}
.text2{
    color: #F7931A;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 7px;
    width: calc(100% - 32px);
    margin-bottom: 170px;
}
.button1{
    height: 60px;
    width: calc(100vw - 110px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 0 0 10px;
    background: #5DD760;
    cursor: pointer;
    margin: 16px;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.button2{
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 10px 10px 0;
    background: #5DD760;
    margin: 0 16px 0 0;
    cursor: pointer;
}
.coin{
    width: 18px;
    height: 18px;
    margin: 0 0 -2px 5px;
}