._{
    display: flex;
    position: fixed;
    width: calc(100% - 32px);
    height: calc(100% - 60px);
    top: 0;
    left: 0;
    gap: 30px;
    background: #15191F;
    z-index: 10;
    padding: 30px 16px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}
.block{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}
.avatar{
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    border-radius: 100%;
}
.avatar2{
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;
    border-radius: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #01b454;
    font-size: 40px;
}
.name{
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.block1{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.input{
    border-radius: 15px;
    background: #282B30;
    border: none;
    height: 54px;
    padding-left: 16px;
    width: calc(100% - 16px);
    color: #FFF;
    font-feature-settings: 'case' on;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 117.647% */
    letter-spacing: -0.24px;
}
.text{
    color: #FFF;
    font-feature-settings: 'case' on;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 117.647% */
    letter-spacing: -0.24px;
}
.block2{
    display: flex;
    gap: 20px;
    padding-right: 20px;
    align-items: center;
}
.workRightSwitch{
    position: relative;
    display: inline-block;
    min-width: 51px;
    height: 31px;
}
.workRightSwitch input {display:none;}
.workRightSlider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #282B30;
    -webkit-transition: .4s;
    transition: .4s;
}
.workRightSlider:before {
    position: absolute;
    content: "";
    height: 27px;
    width: 27px;
    left: 2px;
    bottom: 2px;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .workRightSlider {
    background-color: #01b454;
}

input:focus + .workRightSlider {
    box-shadow: 0 0 1px #01b454;
}
input:checked + .workRightSlider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background-repeat: no-repeat;
    background-position: center;
}
.workRightSlider {
    border-radius: 34px;
}

.workRightSlider:before {
    border-radius: 50%;
}
.save{
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    border-radius: 10px;
    background: #5DD760;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    justify-content: center;
    cursor: pointer;
}