._{
    padding: 26px 16px 0 16px;
}
.header{
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.blocks{
    display: flex;
    gap: 8px;
    margin-top: 11px;
}
.block1{
    width: calc(100% / 3);
    border-radius: 10px;
    background: #282B30;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.img{
    height: 58px;
}
.block1Text{
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 9px;
    width: min-content;
}
.block1Text2{
    color: #5DD760;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 7px;
    margin-bottom: 10px;
}
.block1Text3{
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.5;
    text-decoration-line: line-through;
    margin-right: 5px;
}
.fon{
    background: rgba(0, 0, 0, 0.50);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
}
.menu{
    border-radius: 20px 20px 0 0;
    background: #15191F;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.closeBlock{
    width: calc(100% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: row-reverse;
}
.close{
    cursor: pointer;
}
.img2{
    height: 124px;
    margin: -10px 0 22px 0;
}
.menuText1{
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 18px;
}
.menuText2{
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 80%;
    margin-bottom: 50px;
}
.menuText2_1{
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 80%;
    margin-bottom: 20px;
}
.menuText3_1{
    color: #4DE076;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 5px;
}
.menuText3_2{
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.menuButtons{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 24px;
}
.menuText3{
    color: #4DE076;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
    display: block;
}