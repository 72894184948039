._{
    display: flex;
    margin: 0 0 27px 0;
    padding: 0 16px;
    flex-direction: column;
    gap: 15px;
}
.block{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
}
.block1{
    border-radius: 10px;
    background: #282B30;
    height: 50px;
    width: calc(100% - 29px);
    display: flex;
    align-items: center;
    padding: 0 13px 0 16px;
    justify-content: space-between;
}
.text1{
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.text1_1{
    color: #4B4F55;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.text1_2{
    color: #B18B5B;
}
.text1Block{
    display: flex;
    gap: 9px;
    align-items: center;
}
.block1_1{
    display: flex;
}
.info{
    cursor: pointer;
}
.greenCoin{
    color: #4DE076;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.starsNumber{
    color: #FFF;
    font-size: 13.347px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    top: 7px;
    left: 13px;
}
.star{
    position: relative;
}
.block2{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.block2_1{
    display: flex;
    gap: 8px;
    align-items: center;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.blocks{
    display: flex;
    gap: 7px;
    flex-direction: column;
}
.text2_1{
    color: #4DE076;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.linksBlock{
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    transition: all .3s ease;
}