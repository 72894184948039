._{
    display: flex;
    gap: 7px;
    flex-direction: column;
}

.block{
    border-radius: 14px;
    background: #B18B5B;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.text{
    color: #FFF;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.ok{
    position: absolute;
    margin: 40px -40px 0 0;
}
.fon{
    background: rgba(0, 0, 0, 0.50);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
}
.menu{
    border-radius: 20px 20px 0 0;
    background: #15191F;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.closeBlock{
    width: calc(100% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: row-reverse;
}
.close{
    cursor: pointer;
}
.img2{
    width: 100px;
    height: 100px;
    margin: -10px 0 20px 0;
    border-radius: 200px;
}
.menuText1{
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.menuText2{
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: .5;
    margin-top: 15px;
    margin-bottom: 15px;
}
.menuButtons{
    width: 80%;
}
.menuButtons2{
    width: 100%;
    margin-bottom: 10px;
}