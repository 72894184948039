._{
    display: flex;
    gap: 3px;
}
.mt{
    color: #FFF;
    text-align: center;
    font-size: 15.826px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 3px;
}
.block{
    color: #FFF;
    text-align: center;
    font-size: 9.739px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.value{
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: -23px 0 8px 0;
}
.block1{
    border-radius: 2.841px 2.841px 0px 0px;
    background: #1F1F1F;
    box-shadow: 0px 2.435px 2.435px 0px rgba(0, 0, 0, 0.35);
    width: 34px;
    height: 13px;
}
.block2{
    background: #000;
    width: 34px;
    height: 2px;
}
.block3{
    border-radius: 0px 0px 2.841px 2.841px;
    background: #1F1F1F;
    box-shadow: 0px 1.217px 0px 0px #373A3F;
    width: 34px;
    height: 13px;
}