.blockInfo{
    border-radius: 10px;
    background: #282B30;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.blockInfoText1{
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.blockInfoBlock1{
    display: flex;
    gap: 10px;
    align-items: center;
}
.blockInfoBlock1Number{
    color: #4DE076;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: black;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 5px;
}
.blockInfoBlock1Text{
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.blockInfoBlock2{
    display: flex;
    gap: 10px;
    align-items: center;
}
.coin2{
    width: 32px;
    height: 32px;
    border-radius: 20px;
}
.blockInfoBlock1Text2{
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.starsNumber{
    color: #FFF;
    font-size: 10.395px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    top: 6px;
    left: 9px;
}
.star{
    position: relative;
}