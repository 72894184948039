._{
    padding: 56px 16px 50px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.coin{
    width: 137px;
    height: 137px;
}
.text1{
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 27px;
    margin-bottom: 20px;
}
.blocks{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}
.block{
    display: flex;
    border-radius: 10px;
    background: #282B30;
    padding: 16px;
    width: calc(100% - 32px);
    gap: 16px;
}
.number{
    background: #B18B5B;
    border-radius: 100%;
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.text2{
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.text3{
    color: #8A8C8F;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}
.text3_1{
    color: #4DE076;
}