._{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 57px 0 55px 0;
}
.text1{
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.text2{
    color: #FFF;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}