.startBlock{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    z-index: 10;
}
.background1{
    height: 100%;
    width: auto;
    position: fixed;
}
.startBlockText{
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: fixed;
    top: calc(40%);
    margin: 0 10px;
}
.startBlockButtons{
    display: flex;
    position: fixed;
    bottom: 20px;
    gap: 16px;
}
.icons{
    position: relative;
    cursor: pointer;
}
.icon2X{
    position: absolute;
    top: 18px;
    left: 18px;
}
.icon2T{
    position: absolute;
    top: 12px;
    left: 13px;
}