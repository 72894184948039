._{
    height: 62px;
    width: calc(100% - 32px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
}
.blockUser{
    display: flex;
    gap: 9px;
    align-items: center;
}
.avatar{
    width: 30px;
    height: 30px;
    border-radius: 30px;
}
.avatarImage{
    width: 30px;
    height: 30px;
    border-radius: 30px;
}
.avatarImage2{
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #01b454;
}
.username{
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.friends{
    color: #4DE076;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-right: 10px;
}
.settings{
    display: flex;
    gap: 20px;
    align-items: center;
}
.fon{
    background: rgba(0, 0, 0, 0.50);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}
.buttonSetting{
    cursor: pointer;
    transition: all .6s ease;
}
.buttonSetting3:hover{
    -webkit-animation: 1.2s ease-in-out 0s normal none infinite running trambling-animation;
    -moz-animation: 1.2s ease-in-out 0s normal none infinite running trambling-animation;
    -o-animation: 1.2s ease-in-out 0s normal none infinite running trambling-animation;
    animation: 1.2s ease-in-out 0s normal none infinite running trambling-animation;
}
@keyframes trambling-animation {
    0%, 50%, 100% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
    }
    10%, 30% {
        transform: rotate(-2deg);
        -webkit-transform: rotate(-2deg);
        -moz-transform: rotate(-2deg);
        -o-transform: rotate(-2deg);
        -ms-transform: rotate(-2deg);
    }
    20%, 40% {
        transform: rotate(2deg);
        -webkit-transform: rotate(2deg);
        -moz-transform: rotate(2deg);
        -o-transform: rotate(2deg);
        -ms-transform: rotate(2deg);
    }
}
.buttonSetting2{
    transition: all .6s ease;
    transform: rotate(90deg);
}
.langBlock{
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    position: relative;
    width: 50px;
    justify-content: flex-end;
}
.lang{
    width: 26px;
    height: 26px;
    border-radius: 26px;
    position: absolute;
    left: 0;
    transition: all .3s ease;
    z-index: 0;
    top: -9px;
}
.selectLang{
    z-index: 1;
}
.langOpen{
    top: 24px;
}
.strLang{
    transition: all .3s ease;
    transform: rotate(180deg);
}
.strLangOpen{
    transform: rotate(0deg);
}
.fon2{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
@media (max-width: 449px) {
    .settings{
        gap: 10px;
    }
}