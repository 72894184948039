._{
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #5DD760;
    margin: 16px;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    transition: all .3s ease;
}
.__{
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #34373D;
    margin: 16px;
    color: rgba(255,255,255,.4);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: all .3s ease;
}