._{
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    position: fixed;
    top: 50px;
    padding: 10px;
    background: #8A8C8F;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: calc(90% - 20px);
    z-index: 21;
    left: 5%;
}
.fon{
    background: rgba(0, 0, 0, 0.50);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
}