._{
    display: flex;
    margin: 0 0 27px 0;
    padding: 0 16px;
    flex-direction: column;
    gap: 15px;
}
.block{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
}
.block1{
    border-radius: 10px;
    background: #282B30;
    height: 50px;
    width: calc(100% - 29px);
    display: flex;
    align-items: center;
    padding: 0 13px 0 16px;
    justify-content: space-between;
}
.text1{
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.text1_1{
    color: #4B4F55;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
}
.text1_2{
    color: #B18B5B;
}
.text1Block{
    display: flex;
    gap: 9px;
    align-items: center;
}
.starsNumber{
    color: #FFF;
    font-size: 10.395px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    top: 6px;
    left: 9px;
}
.star{
    position: relative;
}
.stars{
    margin-left: 15px;
    display: flex;
    gap: 7px;
    align-items: center;
    margin-right: 3px;
}
.block1_1{
    display: flex;
}
.info{
    cursor: pointer;
}
.refBlock{
    border-radius: 10px;
    background: #282B30;
    height: 63px;
    display: flex;
    padding: 0 16px;
    justify-content: space-between;
}
.refBlock1{
    display: flex;
    align-items: center;
    gap: 12px;
}
.refBlocks{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.refImg{
    width: 40px;
    height: 40px;
    border-radius: 100%;
}
.avatarImage2{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #01b454;
}
.refName{
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.coin{
    width: 19px;
    height: 19px;
    border-radius: 100%;
}
.coins{
    color: #4DE076;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 4px;
}
.refLevel{
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 4px;
}
.refCountRef{
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 6px;
}
.button{
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
    background: #5DD760;
    cursor: pointer;
}
.fon{
    background: rgba(0, 0, 0, 0.50);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}