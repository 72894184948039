.menuText1{
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 10px 18px 10px;
}
.fon{
    background: rgba(0, 0, 0, 0.50);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
}
.menu{
    border-radius: 20px 20px 0 0;
    background: #15191F;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.closeBlock{
    width: calc(100% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: row-reverse;
}
.close{
    cursor: pointer;
}
.blocks{
    display: flex;
    width: calc(100% - 32px);
    position: relative;
    gap: 16px;
    margin-bottom: 16px;
}
.block{
    display: flex;
    width: calc(50%);
    height: 100px;
    padding: 27px 20px 27px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #34373D;
    cursor: pointer;
}
.block2{
    display: flex;
    width: calc(100% - 32px);
    height: 80px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #34373D;
    cursor: pointer;
    color: #FFFFFF;
    gap: 10px;
    margin-bottom: 46px;
}