._{
    display: flex;
    justify-content: space-evenly;
    padding: 0 0 16px 0;
}
.block{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: all .3s ease;
}
.text{
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: min-content;
}