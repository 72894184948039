._{
    width: 100%;
    height: 100%;
    background: #15191F;
    transition: opacity .3s ease;
}
.background2{
    border-radius: 10px;
    width: calc(100% - 32px);
    margin: 16px;
    height: auto;
}
.fon{
    background: rgba(0, 0, 0, 0.50);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}