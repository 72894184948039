body {
  margin: 0;
  font-family: 'Inter';
  background: #15191F;
}


.openBonusMenu-enter {
  opacity: 1;
  transform: translate(0px, 100%);
}
.openBonusMenu-enter-active {
  opacity: 1;
  transform: translate(0px, 0px);
  transition: opacity 500ms, transform 500ms;
}
.openBonusMenu-exit {
  opacity: 1;
}
.openBonusMenu-exit-active {
  opacity: 1;
  transform: translate(0px, 100%);
  transition: opacity 300ms, transform 500ms;
}

.openBonusFon-enter {
  opacity: 0;
}
.openBonusFon-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.openBonusFon-exit {
  opacity: 1;
}
.openBonusFon-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.openLinks-enter {
  opacity: 0;
  transform: scaleY(0);
}
.openLinks-enter-active {
  opacity: 1;
  transform: scaleY(1);
  transition: all 500ms;
}
.openLinks-exit {
  opacity: 1;
  transform: scaleY(1);
}
.openLinks-exit-active {
  opacity: 0;
  transition: all 300ms;
  transform: scaleY(0);
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}